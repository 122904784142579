import { Instagram, Mail, Youtube } from 'lucide-react';
import { FaStrava } from 'react-icons/fa';

export const INSTAGRAM = {
  name: 'Instagram',
  href: 'https://www.instagram.com/mattjohnson__',
  icon: Instagram,
};
export const STRAVA = {
  name: 'Strava',
  href: 'https://www.strava.com/athletes/74067187',
  icon: FaStrava,
};
export const YOUTUBE = {
  name: 'Youtube',
  href: 'https://youtube.com/channel/UCc8r28YF_y7BRvCR8efjtNA',
  icon: Youtube,
};
export const EMAIL = {
  name: 'Email',
  href: 'mailto:lisa@inalignmentmedia.com',
  icon: Mail,
};
export const social = [INSTAGRAM, STRAVA, YOUTUBE] as const;
