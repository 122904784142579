'use client';
import { Button } from '@streetferret/ui-web/button';
import { AnimatePresence, motion } from 'framer-motion';
import { useWindowScroll, useWindowSize } from 'react-use';
import { social } from '../_lib/social';
import { useEffect, useState } from 'react';

export function SocialLinks() {
  const { y } = useWindowScroll();
  const { height } = useWindowSize();
  const [totalHeight, setTotalHeight] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const documentHeight = document.documentElement.scrollHeight ?? 0;
      setTotalHeight(documentHeight - height);
    }
  }, [height]);

  useEffect(() => {
    setShow(y > 200 && y < totalHeight - 120);
  }, [y, totalHeight]);

  return (
    <div className="fixed bottom-0 right-0 p-1 z-50">
      <AnimatePresence>
        {show && (
          <motion.div
            className="p-1 border rounded-full flex gap-1 bg-background"
            initial={{
              scaleX: 0.5,
              translateY: 100,
            }}
            animate={{
              scaleX: 1,
              translateY: 0,
            }}
            exit={{
              translateY: 100,
            }}>
            {social.map(({ name, href, icon: Icon }) => (
              <Button
                key={href}
                variant={'ghost'}
                asChild
                size={'sm'}
                className="!rounded-full">
                <a href={href}>
                  <Icon className="h-4 w-4" />
                  <span className="sr-only">{name}</span>
                </a>
              </Button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
