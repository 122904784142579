'use client';
import { Button } from '@streetferret/ui-web/button';
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from '@streetferret/ui-web/card';
import { cn } from '@streetferret/utils';
import { useTheme } from 'next-themes';
import { getImageProps } from 'next/image';
import Link from 'next/link';

export interface SponsorData {
  name: string;
  logo: string;
  logoDark?: string;
  href: string;
  whiteBg?: boolean;
  blackBg?: boolean;
  invert?: boolean;
}

export interface SponsorGridProps {
  tieredSponsors: [t1: SponsorData[], t2: SponsorData[], t3: SponsorData[]];
}

export function SponsorGrid({ tieredSponsors }: SponsorGridProps) {
  const [t1, t2, t3] = tieredSponsors;
  return (
    <div className="space-y-12">
      <section className="">
        <CardHeader>
          <CardTitle className="text-3xl font-extrabold">
            Presented by
          </CardTitle>
          <CardDescription className="text-base">
            A special thanks to these companies for making this run possible!
          </CardDescription>
        </CardHeader>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {t1.map(sponsor => (
            <SponsorLink key={sponsor.href} {...sponsor} level="1" />
          ))}
        </div>
      </section>
      <section className="">
        <CardHeader>
          <CardTitle className="text-3xl font-extrabold">
            Supporting Sponsors
          </CardTitle>
          <CardDescription className="text-base">
            Thanks to these companies for their support!
          </CardDescription>
        </CardHeader>
        <div className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-3">
          {t2.map(sponsor => (
            <SponsorLink key={sponsor.href} {...sponsor} level="2" />
          ))}
        </div>
      </section>
      <section className="">
        <CardHeader>
          <CardTitle className="text-3xl font-extrabold">
            Additional Sponsors
          </CardTitle>
        </CardHeader>
        <div className="grid grid-cols-4 gap-6 sm:grid-cols-5 lg:grid-cols-5 pt-3">
          {t3.map(sponsor => (
            <SponsorLink key={sponsor.href} {...sponsor} level="3" />
          ))}
        </div>
      </section>
    </div>
  );
}

interface SponsorLinkProps extends SponsorData {
  className?: string;
  level: '1' | '2' | '3';
}
function SponsorLink({
  logo,
  logoDark,
  name,
  href,
  className,
  whiteBg,
  blackBg,
  invert,
  level,
}: SponsorLinkProps) {
  const common = {
    alt: name,
    width: 400,
    height: 200,
    className: 'rounded',
  };
  const {
    props: { srcSet: dark },
  } = getImageProps({ ...common, src: logoDark ?? logo });
  const {
    props: { srcSet: light, ...rest },
  } = getImageProps({ ...common, src: logo });
  const { resolvedTheme } = useTheme();

  return (
    <Button
      asChild
      variant={'outline'}
      className={cn(
        '!h-auto !p-3',
        level === '3' && '!p-0.5',
        invert && 'invert dark:invert-0',
        whiteBg && 'bg-white hover:bg-white/80',
        blackBg &&
          'bg-black dark:bg-background hover:dark:bg-secondary hover:bg-slate-700',
        className,
      )}>
      <a href={href} target="_blank">
        <picture className={cn(' my-auto')}>
          {resolvedTheme === 'dark' && logoDark ? (
            <source srcSet={dark} />
          ) : (
            <source srcSet={light} />
          )}
          <img {...rest} />
        </picture>
      </a>
    </Button>
  );
}
