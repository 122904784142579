'use client';
import { Avatar } from '@streetferret/ui-web/avatar';
import { motion } from 'framer-motion';
import Image from 'next/image';
import MattHeadshot from '../../_media/headshot.png';

export function About() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="flex flex-col items-center gap-6">
      <motion.div className="flex flex-col items-center gap-3">
        <Avatar className="!h-40 !w-40">
          <Image src={MattHeadshot} alt="Matt Johnson" placeholder="blur" />
        </Avatar>
        <motion.h3 transition={{ y: { stiffness: 1000, velocity: -100 } }}>
          “Fitness changed my life, and it can change yours as well.”
        </motion.h3>
      </motion.div>
      <motion.p className="text-left">
        Matthew Johnson is probably best known for his unique blend of
        competitive spirit and comedy in the world of ultra running. Before he
        made a splash in the endurance and fitness world, Matthew served Nine
        years in the National Guard, with almost four years on active duty
        between Virginia and Iowa. He understands deeply the challenges of
        active duty and returning service members.
      </motion.p>
      <motion.p className="text-left">
        Matthew is passionate about helping others grow into the best version of
        themselves through his work as a coach, and through leading fundraising
        runs for organizations working with our nation’s veterans.
      </motion.p>
      <motion.p className="text-left">
        Starting on October 1st, he will be running 850 miles / 1,368k across
        the state of Texas to raise money for ValorFit.
      </motion.p>
      <motion.p className="text-left">
        ValorFit is a 501.C non profit that is committed to the belief that a
        Veteran's confidence and self-worth isn't left on the battlefield, but
        is renewed through purposeful daily activity within a dedicated local
        community of group fitness facilities and gyms designed to drive
        strength, wellness, and to foster meaningful relationships that inspire
        individual success, growth, and empowerment.
      </motion.p>
      <motion.p className="text-left">
        No donation is too small. Every single dollar helps us change lives.
      </motion.p>
    </motion.div>
  );
}
